import React, { useState, useEffect } from 'react';
import card from '../images/female_avtar.png'; 
import card1 from '../images/man-avatar-icon.jpg';
import chandru from '../images/chandru.jpeg';
import girish from '../images/Girish (2).jpeg';
import '../testimonial/testimonial.css';
import Header from '../header/header';
import Footer from '../footer/footer';
import content2 from '../images/testimoni1.png';
import testi2 from '../images/fidelitus.png';
import testi3 from '../images/ebnus.png';

const Testimonials = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [animating, setAnimating] = useState(false);
  const cardsPerPage = 3;

  const cards1 = [
    { img: card, name: 'Suhasini', occupation: 'HR', location: 'Fedilitus', description: `Leaders recognize the LMS as a valuable tool for enhancing employee development and improving training efficiency. They emphasize its positive impact on overall engagement and productivity within the organization. The ability to monitor progress and outcomes is also seen as a significant advantage, enabling better decision-making and resource allocation.` },
    { img: card, name: 'Varsha', occupation: 'Faculty', location: 'Fedilitus', description: `Faculty members value the LMS for its ease of course management and the robust analytics tools that help them track employee progress. They appreciate the resource-sharing capabilities and the customization options that allow them to tailor their courses to their teaching style. Additionally, they find the customer support responsive and helpful when issues arise.` },
    { img: card1, name: 'Ashish', occupation: 'Employee', location: 'Fedilitus', description: `Employees appreciate the LMS for its user-friendly interface and flexible learning options. They find the courses engaging and interactive, which keeps them motivated. Many highlight the convenience of accessing the platform anytime, fitting their busy schedules, and value the supportive community that fosters collaboration and discussion among peers.` },
    { img: card, name: 'Suhasini', occupation: 'HR', location: 'Fedilitus', description: `Leaders recognize the LMS as a valuable tool for enhancing employee development and improving training efficiency. They emphasize its positive impact on overall engagement and productivity within the organization. The ability to monitor progress and outcomes is also seen as a significant advantage, enabling better decision-making and resource allocation.` },
    { img: card, name: 'Varsha', occupation: 'Faculty', location: 'Fedilitus', description: `Faculty members value the LMS for its ease of course management and the robust analytics tools that help them track employee progress. They appreciate the resource-sharing capabilities and the customization options that allow them to tailor their courses to their teaching style. Additionally, they find the customer support responsive and helpful when issues arise.` },
    { img: card1, name: 'Ashish', occupation: 'Employee', location: 'Fedilitus', description: `Employees appreciate the LMS for its user-friendly interface and flexible learning options. They find the courses engaging and interactive, which keeps them motivated. Many highlight the convenience of accessing the platform anytime, fitting their busy schedules, and value the supportive community that fosters collaboration and discussion among peers.` },
    { img: card, name: 'Suhasini', occupation: 'HR', location: 'Fedilitus', description: `Leaders recognize the LMS as a valuable tool for enhancing employee development and improving training efficiency. They emphasize its positive impact on overall engagement and productivity within the organization. The ability to monitor progress and outcomes is also seen as a significant advantage, enabling better decision-making and resource allocation.` },
    { img: card, name: 'Varsha', occupation: 'Faculty', location: 'Fedilitus', description: `Faculty members value the LMS for its ease of course management and the robust analytics tools that help them track employee progress. They appreciate the resource-sharing capabilities and the customization options that allow them to tailor their courses to their teaching style. Additionally, they find the customer support responsive and helpful when issues arise.` },
    { img: card1, name: 'Ashish', occupation: 'Employee', location: 'Fedilitus', description: `Employees appreciate the LMS for its user-friendly interface and flexible learning options. They find the courses engaging and interactive, which keeps them motivated. Many highlight the convenience of accessing the platform anytime, fitting their busy schedules, and value the supportive community that fosters collaboration and discussion among peers.` },
    
    ];
  const cards2 = [
    { img: chandru, name: 'Chandru', occupation: 'M D', location: 'Ebenus', description: `Leadership views the LMS as a strategic asset for fostering a learning-oriented culture. They highlight its role in streamlining onboarding processes and improving employee retention rates through effective training. Moreover, leaders value the insights gained from data analytics, which help in aligning training programs with organizational goals and assessing their impact on performance.` },
    { img: card1, name: 'Shashidar', occupation: 'Faculty', location: 'Ebenus', description: `Faculty members commend the LMS for its comprehensive content management capabilities. They often note that the platform simplifies the process of creating and updating course materials, allowing them to focus more on teaching. They also appreciate the feedback mechanisms that facilitate communication with students, helping them tailor their approach based on individual needs.` },
    { img: girish, name: 'Girish', occupation: 'Employee', location: 'Ebenus', description: `Employees find the LMS to be a transformative learning tool that enhances their skill development. Many express satisfaction with the diverse course offerings, which cater to various professional interests. They appreciate the gamification elements that make learning fun and engaging, and they often mention how the platform encourages a culture of continuous improvement within the workplace.` },
    { img: chandru, name: 'Chandru', occupation: 'M D', location: 'Ebenus', description: `Leadership views the LMS as a strategic asset for fostering a learning-oriented culture. They highlight its role in streamlining onboarding processes and improving employee retention rates through effective training. Moreover, leaders value the insights gained from data analytics, which help in aligning training programs with organizational goals and assessing their impact on performance.` },
    { img: card1, name: 'Shashidar', occupation: 'Faculty', location: 'Ebenus', description: `Faculty members commend the LMS for its comprehensive content management capabilities. They often note that the platform simplifies the process of creating and updating course materials, allowing them to focus more on teaching. They also appreciate the feedback mechanisms that facilitate communication with students, helping them tailor their approach based on individual needs.` },
    { img: girish, name: 'girish', occupation: 'Employee', location: 'Ebenus', description: `Employees find the LMS to be a transformative learning tool that enhances their skill development. Many express satisfaction with the diverse course offerings, which cater to various professional interests. They appreciate the gamification elements that make learning fun and engaging, and they often mention how the platform encourages a culture of continuous improvement within the workplace.` },
    { img: chandru, name: 'Chandru', occupation: 'M D', location: 'Ebenus', description: `Leadership views the LMS as a strategic asset for fostering a learning-oriented culture. They highlight its role in streamlining onboarding processes and improving employee retention rates through effective training. Moreover, leaders value the insights gained from data analytics, which help in aligning training programs with organizational goals and assessing their impact on performance.` },
    { img: card1, name: 'Shashidar', occupation: 'Faculty', location: 'Ebenus', description: `Faculty members commend the LMS for its comprehensive content management capabilities. They often note that the platform simplifies the process of creating and updating course materials, allowing them to focus more on teaching. They also appreciate the feedback mechanisms that facilitate communication with students, helping them tailor their approach based on individual needs.` },
    { img: girish, name: 'Girish', occupation: 'Employee', location: 'Ebenus', description: `Employees find the LMS to be a transformative learning tool that enhances their skill development. Many express satisfaction with the diverse course offerings, which cater to various professional interests. They appreciate the gamification elements that make learning fun and engaging, and they often mention how the platform encourages a culture of continuous improvement within the workplace.` },
   ];
  const totalPages1 = Math.ceil(cards1.length / cardsPerPage);
  const totalPages2 = Math.ceil(cards2.length / cardsPerPage);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPage((prevPage) => (prevPage % totalPages1) + 1);
    }, 3000);

    return () => clearInterval(timer);
  }, [totalPages1]);

  useEffect(() => {
    setAnimating(true);
    const timer = setTimeout(() => setAnimating(false), 500);
    return () => clearTimeout(timer);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages1) {
      setCurrentPage(page);
    }
  };

  const renderCards = (cards) => {
    const start = (currentPage - 1) * cardsPerPage;
    const end = start + cardsPerPage;
    return cards.slice(start, end).map((card, index) => (
      <div key={index} className={`card ${animating ? 'slide' : ''}`}>
        <div className='name-img'>
          <img src={card.img} alt={card.name} />
          <div className='description1'>
            <h3>{card.name}</h3>
            <p>{card.location}</p>
            <p>{card.occupation}</p>
          </div>
        </div>
        <p>{card.description}</p>
      </div>
    ));
  };

  return (
    <>
      <Header />

      <div className="testimonials">
        <div className='container1'>
          <div className='con1'>
            <h1>Our Clients Our Priority - Know What They Say About Us</h1>
            <p style={{marginLeft:'13px'}}>We focus on delivering client satisfaction, and that reflects in their kind words. Have a look at what our clients have to say about Prola-LMS.</p>
          </div>
          <div className='con2'>
            <img src={content2} alt="Testimonial" />
          </div>
        </div>
        <p>Global leading digital learning app, <br />
          trusted by corporates across the world.</p>
      </div>
      <div id='small-image'>
      <img src={testi2} alt='iconsss'  />
      </div>
      

      <div className="pagination-container">
        <div className="cards-pagination">
          {renderCards(cards1)} {/* Renders the first set of cards */}
        </div>
        <div className="pagination-dots">
          {Array.from({ length: totalPages1 }, (_, index) => (
            <button
              key={index}
              className={`pagination-dot ${index + 1 === currentPage ? 'active' : ''}`}
              onClick={() => handlePageChange(index + 1)}
            />
          ))}
        </div>
      </div>
      <div id='small-image'>
      <img src={testi3} alt='iconsss' />
        </div> 
      <div className="pagination-container">
        <div className="cards-pagination">
          {renderCards(cards2)} {/* Renders the second set of cards */}
        </div>
        <div className="pagination-dots">
          {Array.from({ length: totalPages2 }, (_, index) => (
            <button
              key={index}
              className={`pagination-dot ${index + 1 === currentPage ? 'active' : ''}`}
              onClick={() => handlePageChange(index + 1)}
            />
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Testimonials;
