import React, { useState } from 'react';
import './about.css';  // Assuming you have some CSS for styling
import about1 from '../images/about-1.jpg';
import about2 from '../images/about2.png';
import about3 from '../images/about3.png';
import Header from '../header/header';
import Footer from '../footer/footer';
import founder from '../images/shyamsir.png';
import cofoundershridar from '../images/Sridhar K.png';
import cofoundershuba from '../images/Shubha.png';
import cofoundervenkat from '../images/venkat rami reddy.png';
import techteamprashanth from '../images/pranshu jain.png';
import advsandeep from '../images/sandeep agarval.png';



const AboutUs = () => {
  const [activeTab, setActiveTab] = useState('London');
  const openCity = (cityName) => {
    setActiveTab(cityName);
    
  };
  return (
    <body>
<Header></Header>
<main>
  <div className='myteam'>
      <img src={about1} id='myteam' alt="about1 page" />
      </div>
<div className='content'>
<div className='content1'>

      <p className='title'>About Us</p>
      <p style={{ fontWeight:'600'}}>Prola Tech is an IT services and product-based company offering a broad spectrum of services, including:
      </p>
      <br/>

      <p style={{ fontWeight:'600'}}>● Mobile Application Development

      </p>
      <p style={{ fontWeight:'600'}}>● Web Application Development


</p>    <p style={{ fontWeight:'600'}}>● Internet of Things (IoT)

</p>    <p style={{ fontWeight:'600'}}>● Web Services and


</p>
<p style={{ fontWeight:'600'}}>● Cloud-based Services


</p>
<br/>

<p style={{ fontWeight:'600'}}>Prola Tech provides end-to-end services from planning, developing, implementing, and managing. It helps businesses perform in an efficient, faster, and profitable manner.</p>
<p>We also develop products with cutting edge technologies in various verticals, such as education. Currently we have “Prola-LMS” digital learning mobile application for all competitive exams and corporate courses.
   </p>
   <br/>

   <p>Like all startups, we are fueled with a directional goal, vision, and passion for achieving the best in our area of expertise. Prola Tech is the brainchild of a group of like-minded technology specialists owing more than two decades of IT experience and expertise. We collaborate with a network of engineers, technical consultants, and subject matter experts to deliver services on par with CMMI standards.
    </p>
    <p>
Our end-to-end solutions are hassle-free, cost-effective, and time-saving, making them a best-in-class option for business owners. All this is possible only because we are backed by principal leaders in the IT sector, providing us with intellectual guidance and advice and propelling us to be the best in the industry.
</p>
<br/>
<p>
We are transparent in all our approaches and communication. So what are you waiting for? Call us or write to us, and we assure you of our committed services.</p>
    </div>
    <div className='content2'>
    <img src={about2} id='about2' alt="about2 page" />

</div>
      </div>

<div className='about3'>

<div className='about3-1'>
<img src={about3} id='about3' alt="about3 page"/>
</div>
<div className='abou3-2'>
  <h2>Our</h2>
  <h2>vision</h2>
  <h2>Statement</h2>
<p>To provide a seamless and holistic technology solution to all businesses worldwide. With that in mind, we have ventured into this business and aim to create an exclusive space for ourselves in the market. We wish to work with absolute dedication and be our clients' most trusted organization and partner.</p>
</div>

</div>
<div className='tabs-list'>
        <h1>Our Team Our Pride - Meet Them        </h1>
        <p>Developing an organization that runs successfully requires a long-term vision, a powerhouse of <br/> information, and a full-time guiding force. That's what our founders are all about - Meet our founders!
        </p>
        <div className="tab">
          <button className={`tablinks ${activeTab === 'London' ? 'active' : ''}`} onClick={() => openCity('London')}>Founders</button>
          <button className={`tablinks ${activeTab === 'Paris' ? 'active' : ''}`} onClick={() => openCity('Paris')}>Co-Founders</button>
          <button className={`tablinks ${activeTab === 'Tokyo' ? 'active' : ''}`} onClick={() => openCity('Tokyo')}>Technical Team</button>
          <button className={`tablinks ${activeTab === 'last' ? 'active' : ''}`} onClick={() => openCity('last')}>Company Advisors</button>

        </div>
        <div id="London" className="tabcontent" style={{ display: activeTab === 'London' ? 'block' : 'none' }}>
        <div className="about-us-container">
      <div className="about-us-content">
        <div className="image-section">
          <img src={founder} alt="Prolaskool" />
        </div>
        <div className="text-section">
          <h2>Shyam Sundar</h2>
          <h6><strong>Founder Director</strong></h6>
          <p>There's a time in everyone's lives when we pause and contemplate on the journey, rethink and reinvent. And he decided to begin a new journey with Prola Tech.
          </p>
          <p>
          He comes with more than 20 years of experience in the IT sector with expertise is a plethora of domains, industries and technologies. A go-getter by nature, he derives strength from his vast industry experience and aims to achieve bigger milestones with this venture.          </p>
       
        </div>
      </div>
    </div>
        </div>
        <div id="Paris" className="tabcontent" style={{ display: activeTab === 'Paris' ? 'block' : 'none' }}>
        <div className="about-us-container">
      <div className="about-us-content">
        <div className="image-section">
          <img src={cofoundershuba} alt="Prolaskool" />
        </div>
        <div className="text-section">
          <h2>Mrs.Shubha PM</h2>
          <h6><strong>BSc, MSc. Entrepreneur, Environmentalist and Active Citizen</strong></h6>
          <p>Born and educated in mysore, she is an out-of-box thinker. She is passionate about education and civic awareness for children and volunteers with many organizations who work towards a plastic-free and sustainable living environment. She is always ready to offer her support and guidance to promote a cleaner and greener world. </p>
          <p></p>
        </div>
      </div>
    </div>
    <div className="about-us-container">
      <div className="about-us-content">
        <div className="image-section">
          <img src={cofoundershridar} alt="Prolaskool" />
        </div>
        <div className="text-section">
          <h2>Sridhar K</h2>
          
          <p>
          Mr. K. Sridhar completed his Bachelor of Engineering in I.P.E. from Bangalore Institute of Technologies, Bangalore University. He started his career by working with some of the top Engineering industries. It gave him immense knowledge in capturing excellent managerial capabilities, and his foresight made him a successful businessman. He joined hands with like-minded people to do good work in the education and I.T. sector and wanted to bring in positive societal changes. His vision is to empower urban and rural towns and villages in India by bringing modern technologies and digitally empowered classrooms to their doorstep.  </p>
          </div>
      </div>
    </div>
    <div className="about-us-container">
      <div className="about-us-content">
        <div className="image-section">
          <img src={cofoundervenkat} alt="Prolaskool" />
        </div>
        <div className="text-section">
          <h2>Venkat Rami Reddy</h2>
          
          <p>Mr. Venkat Rami Reddy N, hailing from Kakinada, India, completed his Master of Technology from J.N.T.U. and gained tremendous knowledge in marketing, sales, and communication. He worked in Deloitte for five years and contributed his expertise to Kaktus Info Solution as a Quality Analyst. However, he always wanted to come back to India, construct a stage for learners, and make noteworthy contributions to the Indian education system.</p>
          <p></p>
         </div>
      </div>
    </div>
        </div>
        <div id="Tokyo" className="tabcontent" style={{ display: activeTab === 'Tokyo' ? 'block' : 'none' }}>
        <div className="about-us-container">
      <div className="about-us-content">
        <div className="image-section">
          <img src={techteamprashanth} alt="Prolaskool" />
        </div>
        <div className="text-section">
          <h2>
          Pranshu Jain</h2>
          <h6><strong>Chief Technical Advisor</strong></h6>
          <p>Pranshu Jain, Chief Enterprise Architect at Navikenz, Bengaluru, India, Electrical Engineering from IIT BHU.   </p>
          <p> He is a solution architect and practice leader with over 25 years of experience in providing solution architecture for digital solutions and building technology practices in emerging areas. He has supported customers across Travel, Retail, CPG, Media, Technology and Manufacturing industries.</p>
         
        </div>
      </div>
    </div>
   
   
        </div>
        
        <div id="Tokyo" className="tabcontent" style={{ display: activeTab === 'last' ? 'block' : 'none' }}>
        <div className="about-us-container">
      <div className="about-us-content">
        <div className="image-section">
          <img src={advsandeep} alt="Prolaskool" />
        </div>
        <div className="text-section">
          <h2>
          Sandeep Agarval</h2>
          <h6><strong>Executive Vice President (EVP) at Visionet Systems Inc., Bangalore, India</strong></h6>
         <p>Having Industry Experience of 27+ years and worked for Happiest Minds Technologies, Mindtree, Lucent Technologies, and Tata Infotech. Successfully executed different projects/ programs/ Verticals/ Business Lines and helped many Fortune 500 companies to do outsourcing and build extended quality teams and scale their businesses.   </p>
        </div>
      </div>
    </div>
   
   
        </div>
        </div>


<div className="about-us">
      <div className="content2">
        <h6>@ProlaLMS</h6>
        <h1>How Well <span className="highlight">We Do</span></h1>
        <p>
          Prola-LMS's strategic vision is strongly driven by our clients' needs and building long-term loyalty with them. That’s why we are committed to performing better with every feedback we get from our clients.
        </p>
        <p>
          Prola-LMS Mobile App was launched to meet the expectations of  corporates facing issues with launching their own content on a platform. Our solution surpassed the expectations by being the most trusted digital learning platform that offered various features to our clients and helped them scale their businesses and gain credibility in the market.
        </p>
        {/* <p>
          The Prolaskool Website Application gave a platform for aspiring candidates looking to take real-time mock tests before the main exam. They can register with our website application and access the online mock tests for various competitive exams.
        </p> */}
        <p>
          We settle for nothing less than the best!
        </p>
      </div>
    </div>

</main>
<div id='scroll'>
 <Footer></Footer>
</div>
</body>
  );
};

export default AboutUs;
