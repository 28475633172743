import React, { useState } from 'react';
import '../contact/contact.css';
import Header from '../header/header';
import Footer from '../footer/footer';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [fieldErrors, setFieldErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Reset field error when the user starts typing
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateName = (name)=>{
    const nameRegex = /^[A-Za-z]+([-'][A-Za-z]+)?( [A-Za-z]+([-'][A-Za-z]+)?)*$/;
    return nameRegex.test(name);
  }
  console.log(validateName('helloworld'));
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Reset field errors
    const errors = {
      name: false,
      email: false,
      message: false,
    };

    // Validate form fields
    if (!formData.name || !validateName(formData.name)|| formData.name.length>30) {
      errors.name = true;
    }
    if (!formData.email || !validateEmail(formData.email)) {
      errors.email = true;
    }
    if (!formData.message || formData.message.length > 500 ||formData.message.length<10) {
      errors.message = true;
    }

    // Set field errors in state
    setFieldErrors(errors);

    // If there are any errors, return early
    if (errors.name || errors.email || errors.message) {
      if(errors.name){
        setError('Name is Wrong ');
        return;
      }
      setError('Please fill out all fields correctly.');
      return;
    }

    // Prepare the request payload
    const requestPayload = {
      name: formData.name,
      emailId: formData.email,
      message: formData.message,
    };

    try {
      const response = await fetch('https://onlinemockupdevenv.azurewebsites.net/api/Email/SendEmailForContactPageProlaLms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      });

      const result = await response.json();
      console.log(result);
      if (result== false) {
        alert('Your message has been sent successfully!');
        setFormData({ name: '', email: '', message: '' }); 
      } else {
        alert('Failed to send your message. Please try again.');
      }
    } catch (error) {
      //alert('Your message has been sent s ');
      alert('An error occurred while sending your message. Please try again.');
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header />
      <div className='body'>
        <div className="contact-form">
          <div className='header'>
            <h2>@Prola LMS</h2>
          </div>
          <h1>Book A Demo Now!</h1>
          <p>Please fill out the form to contact us for any queries about our products and services. We promise to get back to you as soon as possible!</p>
          
          {error && <div className="error">{error}</div>}
          {success && <div className="success">{success}</div>}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                value={formData.name} 
                onChange={handleChange} 
                placeholder="Enter your name" 
                required 
                className={fieldErrors.name ? 'error-input' : ''}

              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-mail Id</label>
              <input 
                type="email" 
                id="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                placeholder="Enter your email" 
                required 
                className={fieldErrors.email ? 'error-input' : ''}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message (min 10 characters and max 500 characters)</label>
              <textarea 
                id="message" 
                name="message" 
                value={formData.message} 
                onChange={handleChange} 
                placeholder="Enter your message" 
                required 
                className={fieldErrors.message ? 'error-input' : ''}
                maxLength={500}
              />
              <div className="char-count">
                {formData.message.length}/500
              </div>
            </div>
            <button type="submit">Send <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 3L3 10.53v.98l6.84 2.65L12.48 21h.98L21 3z"></path></svg></button>
          </form>
          
          <div className='bottom'>
            <h3>Contact Info</h3>
            <p>Toll-Free Number</p>
            <a href="tel:18008890734">18008890734</a>
            <p>Address</p>
            <address>
              #7, 3rd floor, 1st Phase,<br />
              12th Cross Road Ideal Homes Township,<br />
              Near Global Village Tech Park,<br />
              Rajarajeshwari Nagar,<br />
              Bengaluru, Karnataka 560098
            </address>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactForm;
