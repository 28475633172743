import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Homepage from './components/home';  // Adjust the path as needed
import AboutUs from './components/about/about.js';
import Contact from './components/contact/contact.js';
import Testimonial from './components/testimonial/testimonial.js';
import Solution1 from './components/solutions/solutions1.js';
import Solution2 from './components/solutions/solution2.js';
import Solution3 from './components/solutions/solution3.js';
import Solution4 from './components/solutions/solution4.js';
import Solution5 from './components/solutions/solution5.js';
import Solution6 from './components/solutions/solution6.js';
import Solution7 from './components/solutions/solution7.js';
import Solution8 from './components/solutions/solution8.js';
import Solution9 from './components/solutions/solution9.js';
import Solution10 from './components/solutions/solution10.js';
import Solution11 from './components/solutions/solution11.js';
import Solution12 from './components/solutions/solution12.js';
import Solution13 from './components/solutions/solution13.js';
import Solution14 from './components/solutions/solution14.js';

import Footer from './components/footer/footer.js';
import Header from './components/header/header.js';



const App = () => {
  return (
    <div>
      <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/solution1" element={<Solution1 />} />
        <Route path="/solution2" element={<Solution2 />} />
        <Route path="/solution3" element={<Solution3 />} />
        <Route path="/solution4" element={<Solution4 />} />
        <Route path="/solution5" element={<Solution5 />} />
        <Route path="/solution6" element={<Solution6 />} />
        <Route path="/solution7" element={<Solution7 />} />
        <Route path="/solution8" element={<Solution8 />} />
        <Route path="/solution9" element={<Solution9 />} />
        <Route path="/solution10" element={<Solution10 />} />
        <Route path="/solution11" element={<Solution11 />} />
        <Route path="/solution12" element={<Solution12 />} />
        <Route path="/solution13" element={<Solution13 />} />
        <Route path="/solution14" element={<Solution14 />} />


        <Route path="/header" element={<Header />} />
        <Route path="/footer" element={<Footer />} />


      </Routes>
    </Router>
    </div>
  );
};

export default App;
