import React from 'react';
import '../footer/footer.css';
import linkedin from'../images/linkedin.png';
import instagram from '../images/instagram.png';
import facebook from '../images/facebook.png';
import butterfly from '../images/butterfly.png';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
function Footer() {

 const scrollToTop=()=>{
    window.scrollTo({top:'0',behavior:'smooth'})
  }

 
  return (
   <>
    <div className="App">
     <footer className="footer">
       <div className="footer-content">
         <div className="company-info">
         <img src={butterfly} id='butterfly'></img>
           <h2>PROLA TECH</h2>
           <address>
             #7, 3rd floor, 1st phase, 12th Cross Road Ideal Homes Township,<br />
             Near Global Village Tech Park,<br />
             Rajarajeshwari nagar,<br />
             Bengaluru, Karnataka 560098<br />
             @2021 All copyright reserved<br />
             <br />
             Toll-Free Number: 18008890734
           </address>
         </div>
         <div className='last'>

         <div className="quick-links">
           <h3>Quick Links</h3>
           <ul>
           <li id='home'>
          <NavLink to="/"  onClick={scrollToTop}>Home</NavLink>
        </li>
             <li id='about'><NavLink to='/about' onClick={scrollToTop}>About Us</NavLink></li>
             {/* <li><NavLink to='/'>Online MockUp</NavLink></li> */}
             <li id='testimonial'><NavLink to='/testimonial'  onClick={scrollToTop}>Testimonials</NavLink></li>
             <li><NavLink to='/contact' onClick={scrollToTop}>Contact Us</NavLink></li>
           </ul>
         </div>
         {/* <div className="support-links">
           <h3>Support</h3>
           <ul>
             <li><a href="termsAndConditions.html">Terms and Conditions</a></li>
             <li><a href="privacyPolicy.html">Privacy Policy</a></li>
             <li><a href="RefundCancellationPolicy.html">Cancellation/Refund Policy</a></li>
           </ul>
         </div> */}
         <div className="social-links">
           <NavLink to="https://www.linkedin.com/company/prola-tech"><img src={linkedin} alt="LinkedIn" /></NavLink>
           <NavLink to="https://www.instagram.com/prola_lms?igsh=Z29rYW85c21rOWZi"><img src={instagram} alt="Instagram" /></NavLink>
           <NavLink to="https://www.facebook.com/profile.php?id=100084624307595"><img src={facebook} alt="Facebook" /></NavLink>
         </div>
         </div>
       </div>
       <div className='slab3'>
         <p>@ All Rights Reserved.</p>
       </div>
     </footer>
   </div>
   </>
  );
}

export default Footer;
