import React, { useState } from 'react'; // Import useState from React
import '../solutions/solution.css';
import automatedEnrollmentImage from '../images/Highlights-image.webp';
import Header from '../header/header';
import Footer from '../footer/footer';
import One from '../images/1st.webp';
import Two from '../images/2nd.webp';
import Three from '../images/3rd.webp';
import Four from '../images/four.webp'
function Solution11() {
    const [activeTab, setActiveTab] = useState('London');
  const openCity = (cityName) => {
    setActiveTab(cityName);
  };
 return (
    <>
    <Header></Header>
    <div className="onboarding-container">
      <div className="contents">
        <h1>Exceptional employee onboarding made exceptionally easy</h1>
        <div className="buttons">
          <button className="demo-button">Book a demo</button>
          <a href="./solution" className="tour-link">Take a Product Tour</a>
        </div>
      </div>
      <div className="images">
        <div className="image-row">
          <img src={One} alt="Image 1" />
          <img src={Two} alt="Image 2" />
        </div>
        <div className="image-row">
          <img src={Three} alt="Image 3" />
          <img src={Three} alt="Image 3" />

        </div>
      </div>
    </div>
    
    <p id='pgrid'>OVERVIEW</p>
    <h2 id='h2grid'>Ramp up new hires. Amp up your business.    </h2>
    <p id='p2grid'>Onboarding doesnt just determine how successful your employees will be in the short term. It also impacts how successful your business will be in the long term. That’s why you need to get onboarding right, from day one.</p>
    
     <div className='main-sol'>
        <div className='images2'>
        <img src={automatedEnrollmentImage} alt="Automated enrollment" className="section-image" />
    

        </div>
        <div className='description'>
        <div className="section-text">
            <h2>Automated enrollment</h2>
            <p>Automate workflows within your LMS and between your LMS and other platforms (HRIS & HCM, etc.) to reduce the time and costs associated with manual program administration, like enrollments.</p>
          </div>
          <div className="section-text">
            <h2>Hyper-personalized learning</h2>
            <p>Leverage AI-powered content recommendations, adaptive learning paths, virtual coaching, and automated upskilling to create tailored experiences for better engagement and faster onboarding.</p>
          </div>
          <div className="section-text">
            <h2>Onboarding content</h2>
            <p>Pull from our ever-growing library or create your own content using AI-based creation tools to deliver consistent, quality onboarding material that meets the diverse needs of your workforce.</p>
          </div>
          
          <div className="section-text">
            <h2>Insights and intelligence</h2>
            <p>Centralize quantifiable feedback from new hires and data from your LMS in your existing BI and data ecosystem to analyze the impact of onboarding on key business outcomes.</p>
          </div>

        </div>

    </div>
    <div className='extra'>
    <h2>Built for the business of learning
    </h2>
    <p>We don’t just deliver exceptional customer education experiences. We deliver exceptional value. See how much your company stands to gain with Docebo.

</p>
    <button className="demo-button2">Book a demo</button>

    </div>
      
    <p id='pgrid'>KEY FEATURES & BENEFITS

</p>
    <h2 id='h2grid'>Create happier, more engaged, loyal,and productive employees—faster</h2>
    <p id='p2grid'>Hook new hires from day one (and ramp them up faster), drive program adoption and completion,standardize and centralize content, and reduce costs with Docebo purpose-built employee onboarding platform.
</p>
    
<div className='main-main'>
     
      <div className="tab1">
        <button
          className={`tablinks ${activeTab === 'London' ? 'active' : ''}`}
          onClick={() => openCity('London')}
        >
          Engage new hires from day 1
        </button>
        <button
          className={`tablinks ${activeTab === 'Paris' ? 'active' : ''}`}
          onClick={() => openCity('Paris')}
        >
          Drive program and content adoption


        </button>
        <button
          className={`tablinks ${activeTab === 'new' ? 'active' : ''}`}
          onClick={() => openCity('new')}
        >
          Accelerate time to performance


        </button>
        <button
          className={`tablinks ${activeTab === 'Tokyo' ? 'active' : ''}`}
          onClick={() => openCity('Tokyo')}
        >
Reduce administration and costs



        </button>
      </div>

      <div id="London" className="tabcontent1" style={{ display: activeTab === 'London' ? 'block' : 'none' }}>
        <h3>Engage new hires from day 1        </h3>
        <p>New employees who feel supported turn into loyal, long-term employees. Increase new hire retention, referrals, and engagement scores with compelling and exciting onboarding programs that make them want to stick around for the long haul.

</p>
<h3>Hyper-personalized experiences
</h3>
<p>
Set employees up for success with AI-powered content and training recommendations that send them down the learning path that’s right for them based on their role, experience, and individual needs. 


</p>
<h3>Interactive and rewarding
</h3>
<p>Onboarding shouldn’t be overwhelming. With gamification, you can use badges, leaderboards, contests, points, and recognition to make the challenge of learning about a new company and role fun and rewarding.

</p>
<h3>Interactive and rewarding
</h3>
<p>Onboarding shouldn’t be overwhelming. With gamification, you can use badges, leaderboards, contests, points, and recognition to make the challenge of learning about a new company and role fun and rewarding.

</p>


      </div>

      <div id="Paris" className="tabcontent1" style={{ display: activeTab === 'Paris' ? 'block' : 'none' }}>
        <h3>Paris</h3>
        <p>Paris is the capital of France.</p>
      </div>

      <div id="Tokyo" className="tabcontent1" style={{ display: activeTab === 'Tokyo' ? 'block' : 'none' }}>
        <h3>Tokyo</h3>
        <p>Tokyo is the capital of Japan.</p>
      </div>
      <div id="new" className="tabcontent1" style={{ display: activeTab === 'new' ? 'block' : 'none' }}>
        <h3>new</h3>
        <p>Tokyo is the capital of Japan.</p>
      </div>
    </div>
   <div className='Book-main'>
   <img src={Four}></img>
<div className='description-image'>

 <h2>
 Onboarding is just the beginning</h2>
 <p>Your employees should always be learning and growing. Not just during onboarding. We have solutions to support all stages of the employee learning journey—from onboarding to compliance training to upskilling and talent development and sales enablement. So you can help your employees and your business grow.</p>
 <button className="demo-button">Book a demo</button>

</div>

   </div>

    
    <Footer></Footer>
    </>
   
  );

}

export default Solution11;
